import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { X } from "react-feather";

import { isLoggedIn, getServices, isMobile } from "../utils/utils";
import { sendEvent } from "../utils/analytics";

import events from "../images/pillars/events/new.svg";
import friendship from "../images/pillars/friendship/new.svg";
import collaborate from "../images/pillars/collaborate/new.svg";
import relationship from "../images/pillars/relationship/new.svg";
import plus from "../images/pillars/Plus.png";

import book_img from "../images/home_background.jpeg";
import circle from "../images/figures/Circle.png";
import circle2 from "../images/figures/Circle2.png";
import oval from "../images/figures/Oval.png";
import rectangle from "../images/figures/tall_rectangle_new.png";
import HoverWrapper from "../components/HoverWrapper.js";

import { THEME } from "./NewSettingsComponents";

import "../styles/Home.css";
import axios from "axios";

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            onMobile: isMobile(),
            hitShapeBreakpoint: this.hitShapeBreakpoint(),
            loggedIn: false,
            gotLoggedIn: false,
            isSubscribed: false,
            isFriendAccount: false,
            isDatingAccount: false,
            friendAccountIntroductionShown: true,
            datingAccountIntroductionShown: true,
        };
        this.setMobile = this.setMobile.bind(this);
        this.closeServiceIntroModal = this.closeServiceIntroModal.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    hitShapeBreakpoint() {
        return window.innerWidth < 640;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.setMobile);
    }

    setMobile() {
        this.setState({onMobile: isMobile(), hitShapeBreakpoint: this.hitShapeBreakpoint()});
    }

    componentDidMount() {
        window.addEventListener("resize", this.setMobile);
        isLoggedIn((newVal) => {
            this.setState({ loggedIn: newVal, gotLoggedIn: true }, () => {
                sendEvent("home");
                if (newVal) {
                    getServices((res) => {
                        if (res !== null) {
                            this.setState({
                                isSubscribed: res.data.isSubscribed,
                                isFriendAccount: res.data.friendAccount,
                                isDatingAccount: res.data.datingAccount,
                                friendAccountIntroductionShown: res.data.friendAccountIntroductionShown,
                                datingAccountIntroductionShown: res.data.datingAccountIntroductionShown,
                            });
                        }
                    });
                }
            });
        });
    }

    renderPillar = (image, title, part1, exclude = false) => {
        const clickForMore = <>
         <div className="click-for-more">
             <img className="plussign" src={plus} />
             Click for more
         </div>
      </>;

        const html = <>
        <div
            style={{
                display: "grid",
                gridautoFlow: "row",
                alignItems: "center",
                alignContent: "start",
                justifyContent: "center",
                justifyItems: "center",
                height: "100%",
                color: THEME.colorAccent,
                rowGap: "7px"}}>
            <img className="pillar-img" src={image} alt="pillar-icon" style={{marginBottom: "4px"}} />
            <div style={{fontSize: "18px", fontWeight: "700", textTransform: "uppercase", letterSpacing: "2px"}}>
                {title}
            </div>
            <div style={{fontSize: "14px", letterSpacing: "1px", textAlign: "center"}}>
                {part1}
            </div>
            {!exclude && clickForMore}
        </div>
      </>;

        return html;
    };

    renderPillars = () => {
        const { hitShapeBreakpoint } = this.state; // different from onMobile

        const linkStyle = {color: THEME.colorSecondary, textDecoration: "underline"};
        const halfMargin = `calc(${THEME.marginSmall} / 2.0)`;
        const descriptionStyle = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "100%",
            marginTop: "10%"};
        const hoverWrapperStyle = {
            width: "240px",
            height: "192px",
            margin: halfMargin
        };

        const circleStyle = hitShapeBreakpoint
            ? {
                height: "150px",
                top: "-75px",
                left: "-75px"
            }
            : {
                top: "-75px",
                left: "5%",
                height: "150px"
            };
        const rectangleStyle = hitShapeBreakpoint
            ? {
                width: "85px",
                top: "-20px",
                right: "-40px"
            }
            : {
                width: "120px",
                top: "-75px",
                right: "-50px"
            };
        const ovalStyle = hitShapeBreakpoint
            ? {
                height: "120px",
                bottom: "calc(57% - 50px)",
                left: "-160px"
            }
            : {
                height: "150px",
                bottom: "-75px",
                left: "-100px"
            };
        const circle2Style = hitShapeBreakpoint
            ? {
                height: "200px",
                right: "-110px",
                bottom: "calc(35% - 100px)"}
            : {display: "none"};
        const rectangle2Style = hitShapeBreakpoint
            ? {
                width: "85px",
                bottom: "-75px",
                left: "-40px"
            }
            : {display: "none"};

        const featuresArea = <>
          <div id="features-list" style={{padding: "60px 40px 60px"}}>
              <div id="desc" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  {/* Have fun talking with others about the books you love{" "}
                  and the music you listen to - it's free to try!{" "} */}
                  <Link to="/register">
                      <div style={{
                          letterSpacing: "2px",
                          borderRadius: "30px",
                          backgroundColor: "white",
                          padding: "9px 40px",
                          width: "max-content",
                          fontWeight: "700",
                          fontSize: "21px",
                          color: "black" }}>
                        Start Here
                      </div>
                  </Link>
              </div>
          </div>
        </>;

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    position: "relative",
                    zIndex: 2
                }}>
                {featuresArea}
                <div style={{position: "relative"}}>
                    <img alt="morphkey-circle"
                        src={circle2}
                        style={{position: "absolute", zIndex: 1, ...circleStyle}}/>
                    <img alt="morphkey-rectangle"
                        src={rectangle}
                        style={{position: "absolute", zIndex: 2, ...rectangleStyle}}/>
                    <img alt="morphkey-oval"
                        src={oval}
                        style={{position: "absolute", zIndex: 2, ...ovalStyle}}/>
                    <img alt="morphkey-circle"
                        src={circle}
                        style={{position: "absolute", zIndex: 2, ...circle2Style}}/>
                    <img alt="morphkey-rectangle"
                        src={rectangle}
                        style={{position: "absolute", zIndex: 2, ...rectangle2Style}}/>
                    <div
                        style={{
                            color: THEME.colorAccent,
                            paddingTop: THEME.marginSmall,
                            zIndex: 3,
                            position: "relative"}}>
                        <div
                            style={{
                                display: "flex",
                                padding: halfMargin,
                                alignItems: "center",
                                justifyContent: "center",
                                flexWrap: "wrap"}}>
                            <HoverWrapper style={hoverWrapperStyle}>
                                {this.renderPillar(friendship, "CONVERSATION",
                                    "Talk and discuss with others in your city about music, books, and film.",
                                // "Use our general profile and interest questions to meet friends in your " +
                                // "city, at events, and while traveling! ",
                                // "Our interest questions cover music, film, architecture, food, photography, " +
                                // "art, books, video games, and more."
                                )}
                                <div style={descriptionStyle}>
                                    Both users schedule an audio or video call before talking or messaging.<br />
                                    <Link to="/profile-questions" style={linkStyle}>
                                        View our profile questions here.
                                    </Link>
                                </div>
                            </HoverWrapper>
                            <HoverWrapper style={hoverWrapperStyle}>
                                {this.renderPillar(collaborate, "COLLABORATE",
                                    "Use the collaborate board to start projects.",
                                )}
                                <div style={descriptionStyle}>
                                Search for other users with similar interests and start a project with them.
                                </div>
                            </HoverWrapper>
                            <HoverWrapper style={hoverWrapperStyle}>
                                {this.renderPillar(relationship, "RELATIONSHIP",
                                    `Form meaningful relationships based on shared interests
                                    with this separate service.`,
                                // "A meaningful relationship starts with how you look for one. " +
                                // "That's why photos don't load immediately.",
                                // "A user's profile photos do not load immediately, so instead of browsing by " +
                                // "photos, you can read someone's profile and get to know them first."
                                )}
                                <div style={descriptionStyle}>
                                Read someone's profile and get to know them first; photos load after one minute.
                                </div>
                            </HoverWrapper>
                            {/*<HoverWrapper style={hoverWrapperStyle}>*/}
                            {/*    {this.renderPillar(events, "EVENTS",*/}
                            {/*        "Use our guide to find new events and places.",*/}
                            {/*    )}*/}
                            {/*    <div style={descriptionStyle}>*/}
                            {/*    We're currently supporting Los Angeles, San Francisco, New York, and Tokyo.<br />*/}
                            {/*        <Link to="/events" style={linkStyle}>Check out our events.</Link>*/}
                            {/*    </div>*/}
                            {/*</HoverWrapper>*/}
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: THEME.marginSmall,
                            marginTop: THEME.marginXSmall,
                            zIndex: 3,
                            position: "relative"}}>
                        {/* <Link to="/register">
                            <Button type="primary" style={{fontSize: "18px", letterSpacing: "1.5px"}}>
                            LET'S GET STARTED
                            </Button>
                        </Link> */}
                    </div>
                </div>
            </div>
        );
    };

    renderFeatures = () => (
        <div id="features-list">
            {/* <h1 id="why">WHY MORPHKEY?</h1> */}
            {/* <h1 id="why">WELCOME TO MORPHKEY</h1> */}
            <div className="features-list-item" id="face-to-face">
                <h1>Face-to-Face Communication</h1>
                {/* <p>
                    Both users must approve before they can communicate on Morphkey.
                    Instead of text messages, we use video and audio calling when you first contact someone.
                    We deter fake accounts and spammers to create an enjoyable and responsible community.
                </p> */}
                <p>
                    <b>There’s a difference between texting and video chatting.</b>
                </p>
                <p>
                    Morphkey uses video and audio calling when both users agree to converse.
                </p>
                <p>
                    Search for others who share your interests.
                </p>
            </div>
            <div className="features-list-item" id="face-to-face">
                <h1>Privacy</h1>
                <p>
                    <b>There are no ads on Morphkey.</b>
                </p>
                <p>
                    We do not sell or share your data with advertisers
                    and we do not track you across the web.
                </p>
                <p>
                    We do not use your profile and photos for promotional purposes.
                </p>
            </div>
        </div>
    );

    renderIntro = () => {
        const html = <>
            {/* <div className="intro-container-text"
                style={{
                    padding,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "start"}}>
                    <span className="intro-container-text--1"><b>CONNECT</b></span>
                    <span className="intro-container-text--2">with others in</span>
                    <span className="intro-container-text--3">your city based on</span>
                    <span className="intro-container-text--4"><b>COMMON</b></span>
                    <span className="intro-container-text--5"><b>INTERESTS</b></span>
                </div>
            </div> */}
            <div className="phones-frame"
                style={{
                    backgroundImage: `linear-gradient(to bottom right, rgba(0,0,0,0.2), rgba(0,0,0,0.2)),
                        url(\"${book_img}\")`,
                    backgroundSize: "cover",
                    height: isMobile() ? "400px" : "800px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    position: "relative" }}
            >
                <div style={{
                    backgroundColor: THEME.colorAccent,
                    height: isMobile() ? "130px" : "300px",
                    width: isMobile() ? "230px" : "400px",
                    alignSelf: "flex-end",
                    marginLeft: "auto",
                    marginBottom: isMobile() ? "auto" : "40px",
                    marginRight: isMobile() ? "0px" : "40px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: isMobile() ? "9px" : "inherit",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <span className="intro-container-text--2" style={{
                            fontSize: "1.4em",
                            alignSelf: "flex-start",
                            marginBottom: isMobile() ? ".3rem" : ".7rem"
                        }}>
                            Photo from
                        </span>
                        <span className="intro-container-text--2" style={{
                            fontSize: "1.4em",
                            marginBottom: isMobile() ? "1rem" : "2rem",
                            alignSelf: "flex-start",
                            color: THEME.colorSecondary
                        }}>
                            <b>The L.A. Book Scavenger Hunt:</b>
                        </span>
                        <span className="intro-container-text--1" style={{
                            borderBottom: "solid white 2px"
                        }}>
                            <b>
                                A Morphkey Initiative
                            </b>
                        </span>
                    </div>
                </div>
                <div style={{
                    backgroundColor: THEME.colorAccent,
                    height: isMobile() ? "140px" : "220px",
                    width: isMobile() ? "200px" : "400px",
                    alignSelf: "flex-start",
                    marginLeft: "auto",
                    marginTop: "40px",
                    marginBottom: isMobile() ? "20px" : "inherit",
                    marginRight: isMobile() ? "0px" : "40px",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: isMobile() ? "9px" : "inherit",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <span className="intro-container-text--1" style={{marginBlock: "12px 8px"}}><b>Unlocking Social Life</b></span>
                        <span className="intro-container-text--2">for people who love</span>
                        <span className="intro-container-text--2">Music, Food, Art, Film,</span>
                        <span className="intro-container-text--2">Books, Photography, and more.</span>
                        <Link
                            to="what-is-morphkey"
                            style={{
                                color: "#F17432",
                                fontWeight: "bolder",
                                fontSize: "1.3em",
                                marginBlock: "8px 12px",
                                textDecoration: "underline"
                            }}>
                            Learn More
                        </Link>
                    </div>
                </div>
            </div>
        </>;
        return html;
    };

    renderPrompt = () => {
        return (
            <div id="home-prompt-outer-wrapper"
                 style={{padding: THEME.marginSmall, margin: 0, position: "relative", zIndex: 1}}>
                <p id="home-prompt-content" style={{padding: 0, margin: 0}}>
                    Morphkey is <b>FREE</b> to try. <br/>
                    {/* It’s only $10/month for <b>ALL SERVICES </b> if */}
                    {/* you choose to register after your trial. <br/>  */}
                    There are <b>NO ADS</b> and <b>YOUR DATA IS NEVER SOLD</b>.
                    {/* Sound interesting? <Link to="/register">Sign up for our free 60-day subscription!</Link> */}
                </p>
                {/* <Link to="/register" className="tab">
                    <div className="btn" id="signupAd">
                        SIGN UP
                    </div>
                </Link> */}
            </div>
        );
    };

    renderVideo = () => {
        return (
            <div style={{
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                height: 0,
                marginBottom: 15,
            }}>
                <iframe
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                    }}
                    frameBorder="0"
                    src={process.env.REACT_APP_VIDEO_URL}
                />
            </div>
        );
    };

    renderPillarLinks = () => {
        const halfMargin = `calc(${THEME.marginSmall} / 2.0)`;
        const itemStyle = {width: "240px", height: "180px", margin: halfMargin};

        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <div
                    id="features-list"
                    style={{padding: "10px 0", backgroundColor: "white"}}>
                </div>
                <div
                    style={{
                        position: "relative",
                        zIndex: 3,
                        display: "flex",
                        padding: halfMargin,
                        alignItems: "center",
                        justifyContent: "center",
                        flexWrap: "wrap"}}>
                    <Link to={"/conversation"} key={"conversation"} style={itemStyle}>
                        {this.renderPillar(friendship, "CONVERSATION",
                            "Click here to talk to others in your city with " +
                                    "similar interests.", true
                        )}
                    </Link>
                    {this.state.isDatingAccount &&
                            <Link to={"/relationship"} key={"relationship"} style={itemStyle}>
                                {this.renderPillar(relationship, "RELATIONSHIP",
                                    "Click here to form meaningful relationships " +
                                    "based on shared interests.", true
                                )}
                            </Link>
                    }
                    <Link to={"/collaborate"} key={"collaborate"} style={itemStyle}>
                        {this.renderPillar(collaborate, "COLLABORATE",
                            "Click here to collaborate and start projects.", true
                        )}
                    </Link>
                    {/*<Link to={"/events"} key={"events"} style={itemStyle}>*/}
                    {/*    {this.renderPillar(events, "EVENTS",*/}
                    {/*        "Click here to find new events and places.", true*/}
                    {/*    )}*/}
                    {/*</Link>*/}
                </div>
            </div>
        );
    };

    renderLoginIntro = () => (
        <div style={!isMobile() ? {display: "flex", height: "58vh"} : {display: "flex"}}>
            <div
                className="intro-container-text"
                style={{alignItems: "center"}}>
                <span
                    className="intro-container-text--1"
                    style={{marginBottom: "0px"}}>
                    <b>Welcome to</b>
                </span>
                <span
                    className="intro-container-text--1"
                    style={{color: "white"}}>
                    <b>Morphkey!</b>
                </span>
            </div>
            {/* <div className="phones-frame"> */}
            {/* <img className="phones-frame-img" alt="morphkey_intro_img" src={Pic1} /> */}
            {/* <Carousel extraStyle={{height: "100%"}} imageUrls={[Pic1]}/>
            </div> */}
        </div>
    );

    renderServiceIntroModal() {
        let modalMsg = null;
        if (this.state.isFriendAccount && !this.state.friendAccountIntroductionShown) {
            const link = <Link to="/subscribe" target="_blank" style={{textDecoration: "underline"}}>
                subscribe under account settings
            </Link>;
            modalMsg = <p>
                {`Thanks for joining Morphkey! You now have access to
                ${process.env.REACT_APP_OUTGOING_CALL_LIMIT} free audio calls.
                For videocalls and more,`} {link}.
            </p>;
        } else if (this.state.isDatingAccount && !this.state.datingAccountIntroductionShown) {
            modalMsg = `Morphkey's Relationship is designed to help you get to know another user well,
            by shifting emphasis away from managing communications to the conversation itself. When
            you receive notifications you can choose one, and the rest are cleared, but you will
            still receive talk requests and can view them after every call.`;
        }
        const showServiceIntroMsg = (!this.state.isSubscribed && modalMsg) ? true : false;

        const modal = <>
            <Modal id="talkSchedule" isOpen={showServiceIntroMsg} style={{
                height: "auto",
                ...(this.state.onMobile ? {} : { width: "50vw", maxWidth: "800px" }),
                backgroundColor: "#9FC6CC",
                marginBottom: "40px",
            }}>
                <X stroke="white" id="close-modal" size="25" onClick={this.closeServiceIntroModal} />
                <h1 style={{textAlign: "justify", fontSize: "20px",
                    ...(this.state.onMobile ? { paddingLeft: "50px", paddingRight: "50px", } : {})}}
                className="chat-modal">
                    {modalMsg}
                </h1>
            </Modal>
        </>;

        return modal;
    }

    closeServiceIntroModal() {
        if (this.state.isFriendAccount && !this.state.friendAccountIntroductionShown) {
            axios.post("/api/users/service-intro-shown/friend");
            this.setState({ friendAccountIntroductionShown: true });
        } else if (this.state.isDatingAccount && !this.state.datingAccountIntroductionShown) {
            axios.post("/api/users/service-intro-shown/dating");
            this.setState({ datingAccountIntroductionShown: true });
        }
    }

    render() {
        if (!this.state.gotLoggedIn) {
            return <div className="home-page tile-background" id="home-page-no-got-login" />;
        }

        let content;

        if (this.state.loggedIn) {
            const modal = this.renderServiceIntroModal();
            content = <>
            {this.renderLoginIntro()}
            {this.renderPillarLinks()}
            {modal}
          </>;
        } else {
            content = <>
            {this.renderIntro()}
            {/* {this.renderFeatures()} */}
            {this.renderPillars()}
            {/* {this.renderVideo()} */}
            {this.renderPrompt()}
          </>;
        }

        const html = <>
          <div
              style={{
                  width: "100%",
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  overflowX: "hidden"}}>
              {content}
          </div>
        </>;
        return html;

    }
}

export default Home;
