import React, {Component, lazy, Suspense} from "react";

import {Redirect, Route, Switch} from "react-router-dom";
import axios from "axios";

import {Settings} from "./Settings";
import {sendPageView} from "../utils/analytics";

import ContentArea from "./header/ContentArea";
import Home from "./Home";
import UserProfile from "./profile/UserProfile";

const Delete = lazy(() => import("./Delete"));
const Events = lazy(() => import("./Events"));
const Search = lazy(() => import("./Search"));

const Login = lazy(() => import("./auth/Login"));
const Verify = lazy(() => import("./auth/Verify"));
const Register = lazy(() => import("./auth/Register"));
const Profiles = lazy(() => import("./auth/Profiles"));
const ResetPassword = lazy(() => import("./auth/ResetPassword"));
const ForgotPassword = lazy(() => import("./auth/ForgotPassword"));

const Chat = lazy(() => import("./chat/Chat"));
const Pending = lazy(() => import("./pending/Pending"));
const ProfileQuestions = lazy(() => import("./ProfileQuestions"));

const Collaboration = lazy(() => import("./Collaboration"));

const Area = lazy(() => import("./events/Area"));
// const Lentil = lazy(() => import("./events/Lentil"));
const SubmitEvent = lazy(() => import("./events/SubmitEvent"));
const SubmitPlace = lazy(() => import("./events/SubmitPlace"));
const SingleLentilPage = lazy(() => import("./events/SingleLentilPage"));
const SingleEventOrPlacePage = lazy(() => import("./events/SingleEventOrPlacePage"));

const SubscribePage = lazy(() => import("./Subscribe"));
const TermsOfUse = lazy(() => import("./TermsOfUse"));
const WhatIsMorphkey = lazy(() => import("./WhatIsMorphkey"));
const FAQ = lazy(() => import("./FAQ"));
const ContactUs = lazy(() => import("./ContactUs"));
const Privacy = lazy(() => import("./Privacy"));

class Main extends Component {

    constructor(props) {
        super(props);

        this.state = {
            incomplete: false,
            gotSettings: false
        };
    }

    componentDidMount() {
        const cb = () => {
            this.setState({
                incomplete: false,
                gotSettings: true,
            });
        };


        axios.get("/api/settings").then((result) => {
            if (result.data.success) {
                const currentUser = result.data.currentUser;

                this.setState({
                    gotSettings: true,
                    incomplete: currentUser.incomplete,
                });
            } else {
                cb();
            }
        }).catch(cb);
    }

    render() {
        sendPageView(window.location.pathname);
        const { userInfo } = this.state;

        if (!this.state.gotSettings) {
            return <div id="main-wrapper" />;
        }

        if (window.location.pathname !== "/profiles" && this.state.incomplete) {
            return <Redirect to="/profiles" />;
        }

        return (
            <ContentArea /*className={`page-wrapper ${this.props.acceptedCookies ? "" : "no-ok"}`}*/>
                <Suspense fallback={<div></div>}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/login" component={Login} />
                        <Route path="/events" component={Events} />

                        <Route path="/profile-questions" component={ProfileQuestions} />

                        <Route exact
                            path="/chat/collaborate"
                            render={props =>
                                <Chat
                                    {...{...props,
                                        chatType: "collaboration"
                                    }}
                                />
                            }
                        />
                        <Route exact
                            path="/chat/relationship"
                            render={props =>
                                <Chat
                                    {...{...props,
                                        chatType: "dating"
                                    }}
                                />
                            }
                        />
                        <Route exact
                            path="/chat/conversation"
                            render={props =>
                                <Chat
                                    {...{...props,
                                        chatType: "friends"
                                    }}
                                />
                            }
                        />
                        <Route exact
                            path="/pending/conversation"
                            render={props =>
                                <Pending
                                    {...{...props,
                                        profileType: "conversation"
                                    }}
                                />
                            }
                        />
                        <Route exact
                            path="/pending/relationship"
                            render={props =>
                                <Pending
                                    {...{...props,
                                        profileType: "relationship"
                                    }}
                                />
                            }
                        />
                        <Route exact
                            path="/pending/collaborate"
                            render={props =>
                                <Pending
                                    {...{...props,
                                        profileType: "collaborate"
                                    }}
                                />
                            }
                        />
                        <Route exact path="/settings"
                            component={Settings}/>
                        <Route exact path="/delete"
                            component={Delete}/>
                        <Route exact
                            path="/conversation"
                            render={props =>
                                <Search
                                    {...{...props,
                                        filterType: "Friend",
                                        profileName: "Friendship",
                                        profileType: "Friendship",
                                        subscribeFrom: "friendship",
                                        validAccountKey: "friendAccount",
                                    }}
                                />}/>
                        <Route exact
                            path="/relationship"
                            render={props =>
                                <Search
                                    {...{...props,
                                        filterType: "Dating",
                                        profileType: "Dating",
                                        profileName: "Relationship",
                                        subscribeFrom: "relationship",
                                        validAccountKey: "datingAccount",
                                    }}
                                />}/>

                        <Route exact path="/collaborate" component={Collaboration}/>
                        <Route exact path="/collaborate/post/:postId" component={Collaboration} />

                        <Route path="/register" component={Register} />
                        <Route path="/profiles" component={Profiles} />

                        <Route path="/verify/" component={Verify}/>
                        <Route path="/user/submit/event" component={SubmitEvent}/>
                        <Route path="/user/submit/place" component={SubmitPlace}/>
                        <Route path="/forgot/" component={ForgotPassword}/>
                        <Route path="/reset/" component={ResetPassword}/>
                        <Route exact path="/areas/:areaUrl" component={Area}/>
                        {/* <Route exact path="/areas/:areaUrl/lentil" component={Lentil}/> */}
                        <Route exact path="/event/:urlID" render={
                            props => <SingleEventOrPlacePage {...{...props, type:"event"}} />}/>
                        <Route exact path="/place/:urlID" render={
                            props => <SingleEventOrPlacePage {...{...props, type:"place"}} />}/>
                        <Route exact path="/lentil/:urlID" component={SingleLentilPage} />
                        <Route exact path="/contactus" component={ContactUs}/>
                        <Route exact path="/profile/conversation/:randInt" render={
                            props => <UserProfile
                                {...{...props,
                                    profileType: "friend",
                                    excludedCategories: ["Dating", "Collaboration"]
                                }} />}/>
                        <Route exact path="/profile/relationship/:randInt" render={
                            props => <UserProfile
                                {...{...props,
                                    profileType: "dating",
                                    excludedCategories: ["Collaboration"]
                                }} />}/>
                        <Route exact path="/profile/collaborate/:randInt" render={
                            props => <UserProfile
                                {...{...props,
                                    profileType: "collaborate",
                                    excludedCategories: ["Dating"]
                                }} />}/>
                        <Route exact
                            path="/collaborate/music"
                            render={props =>
                                <Search
                                    {...{...props,
                                        filterType: "Collaboration",
                                        profileType: "Collaborate",
                                        profileName: "Collaborate",
                                        subscribeFrom: "collaborate",
                                        validAccountKey: "collabMusic",
                                    }}
                                />}/>
                        <Route exact path="/subscribe" component={SubscribePage}/>
                        <Route exact path="/terms-of-use" component={TermsOfUse} />
                        <Route exact path="/what-is-morphkey" component={WhatIsMorphkey} />
                        <Route exact path="/faq" component={FAQ} />
                        <Route exact path="/privacy-policy" component={Privacy} />
                    </Switch>
                </Suspense>
            </ContentArea>
        );
    }
}

export default Main;
